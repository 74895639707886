



























































































































































































































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import countriesList from "@/assets/data/countries.json";
import useParticipant from "@/use/participant";
import { v4 as uuid } from "uuid";
import useErrors from "@/use/errorHandling";

export default defineComponent({
  props: {
    participantId: {
      type: String,
      required: true,
      default: "",
    },
    currency: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props, { root, emit }) {
    const { getParticipantInvoiceType } = useParticipant({ root });
    const { getErrorMessageByText } = useErrors({ root });
    const state = reactive({
      success: false,
      error: false as boolean | number,
      participant: {},
      loading: true,
      buttonDisabled: false,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`participant/${props.participantId}`)
        .then(({ data: { participant } }) => {
          state.participant = participant;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const editRequest = (status: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.buttonDisabled = true;

      axiosInstance
        .put(
          `participant/${props.participantId}/change-request/${status}`,
          {},
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          state.success = true;
          state.error = false;
          state.buttonDisabled = false;
          if (status === "accept") {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "primary",
              message: root.$tc(
                "panel.event.participant.editRequest.successAccept"
              ),
            });
          } else {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "primary",
              message: root.$tc(
                "panel.event.participant.editRequest.successReject"
              ),
            });
          }

          setTimeout(() => {
            emit("close-dialog");
            emit("fetch-data");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          state.buttonDisabled = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessageByText(error.response.data.error),
          });
        });
    };

    return { state, editRequest, countriesList, getParticipantInvoiceType };
  },
});
