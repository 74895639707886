import { SetupContext } from "@vue/composition-api";
export default function useErrors({ root }: Partial<SetupContext>) {
  const getErrorMessageByText = (error: string) => {
    switch (error) {
      case "Template for participant edition additional fee not found":
        return `${root?.$tc(
          "errors.templateForParticipantEditionAdditionalFeeNotFound"
        )}`;
      case "Payment credentials not found":
        return `${root?.$tc("errors.paymentCredentialsNotFound")}`;
      case "Participant not found":
        return `${root?.$tc("errors.participantNotFound")}`;
      case "Changes not found'":
        return `${root?.$tc("errors.changesNotFound'")}`;
      default:
        return `${root?.$tc("errors.default")}`;
    }
  };

  return {
    getErrorMessageByText,
  };
}
